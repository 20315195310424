import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`When building sites using the Carbon Gatsby Theme, there are a number of
different ways you can host your site. While the Carbon team does not have an
opinionated stance on how you host your projects, these are some resources
others have found helpful for hosting.`}</p>
    </PageDescription>
    <h2>{`GH Pages`}</h2>
    <p><a parentName="p" {...{
        "href": "https://pages.github.com/"
      }}>{`GitHub Pages`}</a>{` pages is a simple, low maintenance approach to get your site hosted quickly. For internal sites, or sites with proprietary information, IBMers can leverage `}<a parentName="p" {...{
        "href": "https://w3.ibm.com/help/#/article/github_ent_ibm/github_overview?requestedTopicId=github_overview"
      }}>{`Github Enterprise`}</a>{`. Regardless of whether your projects is external or internal, there are a few steps (see below) to allow your site to work with GitHub Pages.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/how-gatsby-works-with-github-pages/"
      }}>{`Get started`}</a></p>
    <h3>{`Examples`}</h3>
    <p><a parentName="p" {...{
        "href": "https://pages.github.ibm.com/fed/"
      }}>{`https://pages.github.ibm.com/fed/`}</a>{` -
`}<a parentName="p" {...{
        "href": "https://github.ibm.com/FED/fed.github.ibm.com"
      }}>{`View configuration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://pages.github.ibm.com/ai-applications/design/"
      }}>{`https://pages.github.ibm.com/ai-applications/design/`}</a>{` -
`}<a parentName="p" {...{
        "href": "https://github.ibm.com/ai-applications/design"
      }}>{`View configuration`}</a></p>
    <h2>{`Cirrus`}</h2>
    <p>{`Built on Redhat OpenShift, this is the CIO-preferred solution for internally
hosted applications and is provided to all IBMers.`}</p>
    <p><a parentName="p" {...{
        "href": "https://w3.ibm.com/w3publisher/cio-cirrus-platform"
      }}>{`Get started`}</a></p>
    <h3>{`Examples`}</h3>
    <p><a parentName="p" {...{
        "href": "https://w3.ibm.com/design"
      }}>{`https://w3.ibm.com/design`}</a>{` -
`}<a parentName="p" {...{
        "href": "https://github.ibm.com/Design/w3-design/blob/master/Dockerfile"
      }}>{`View configuration`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      